import Vue from 'vue'
import DownloadService from '@/shared/services/download-service'
import { BaseApi } from '@/api/base-api'
import { RESOURCE_SALE_INVOICES } from '@/shared/constants/resources'

class SaleInvoicesApi extends BaseApi {
  constructor(resource) {
    super(resource)
    this.resource = resource
  }

  create(data, notificationsVisible = true) {
    return super.create(data, this.resource, notificationsVisible)
  }

  edit(id, data, notificationsVisible = true) {
    return super.edit(id, data, this.resource, notificationsVisible)
  }

  async printSaleInvoice(saleInvoiceId) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`${this.resource}/print-sale-invoice/${saleInvoiceId}`, {}, { responseType: 'blob' })
        const filename = DownloadService.getFilenameFromResponse(response)
        DownloadService.resolveAndDownloadBlob(response.data, filename)
        resolve()
      } catch (error) {
        console.error(error)
        Vue.$toast.error('Error al descargar el archivo. Por favor inténtelo de nuevo mas tarde.')
        reject(error)
      }
    })
  }

  listSeries(saleInvoiceCategory) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`${this.resource}/serie`, { sale_invoice_category: saleInvoiceCategory })
        resolve(response.data)
      } catch (error) {
        console.error(error)
        Vue.$toast.error('Ocurrió un error al obtener el listado de recursos. Por favor inténtelo de nuevo mas tarde.')
        reject(error)
      }
    })
  }

  getSaleInvoiceNumber(saleInvoiceCategory, saleInvoiceSerie, saleInvoiceId) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`${this.resource}/number`, {
          sale_invoice_category: saleInvoiceCategory, serie: saleInvoiceSerie, id: saleInvoiceId,
        })
        resolve(response.data)
      } catch (error) {
        console.error(error)
        Vue.$toast.error('Ocurrió un error al obtener el número de factura. Por favor inténtelo de nuevo mas tarde.')
        reject(error)
      }
    })
  }

  fixCertification(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`${this.resource}/fix-certification/${id}`)
        resolve(response.data)
      } catch (error) {
        Vue.$toast.error('Ocurrió un error al corregir la factura. Por favor inténtelo de nuevo mas tarde.')
        reject(error)
      }
    })
  }

  async jobList(filters) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`/retentions/list-jobs`, filters)
        resolve(response.data)
      } catch (error) {
        if (error.response?.data?.error) {
          Vue.$toast.error(error.response?.data?.error)
        }

        reject(error)
      }
    })
  }
}

export default new SaleInvoicesApi(RESOURCE_SALE_INVOICES)
