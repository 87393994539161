import { BaseApi } from "@/api/base-api";
import { RESOURCE_JOBS_EXPECTED_COST_LINES } from "@/shared/constants/resources";
import Vue from "vue";

class JobExpectedCostLinesApi extends BaseApi {
  constructor(resource) {
    super(resource);
    this.resource = resource;
  }

  createOrderByJobExpectedCostLine(jobExpectedCostLineId, provider) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(
          `/${this.resource}/create-order/${jobExpectedCostLineId}`,
          { provider }
        );
        Vue.$toast("Recurso creado con éxito.");
        resolve(response.data);
      } catch (error) {
        console.error(error);
        Vue.$toast.error(
          "Ocurrió un error al crear el recurso. (Revisar estado del proveedor)"
        );
        reject(error);
      }
    });
  }

  editCostLine(id, data, notificationsVisible = true) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(
          `/${this.resource}/${id}`,
          data
        );
        resolve(response.data);
        if (notificationsVisible) {
          Vue.$toast("Recurso actualizado con éxito.");
        }
      } catch (error) {
        if (notificationsVisible) {
          console.error(error)
          Vue.$toast.error('Ocurrió un error al actualizar el recurso. Por favor inténtelo de nuevo más tarde.')
        }
        reject(error);
      }
    });
  }
}

export default new JobExpectedCostLinesApi(RESOURCE_JOBS_EXPECTED_COST_LINES);
