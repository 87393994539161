import auth from '@/middleware/auth'
import { RESOURCE_CONFIGURATION } from '@/shared/constants/resources'

export default [
  {
    path: '/certification-reports',
    meta: {
      middleware: [auth],
      resource: RESOURCE_CONFIGURATION,
      headerVisible: false,
    },
    name: 'certificationReports',
    component: () => import('@/views/certifications-reports/List.vue'),
  },
  {
    path: '/financial-limit-reports',
    meta: {
      middleware: [auth],
      resource: RESOURCE_CONFIGURATION,
      headerVisible: false,
    },
    name: 'financialLimitReports',
    component: () => import('@/views/financial-limit-reports/List.vue'),
  },
  {
    path: '/sales-orders-reports',
    meta: {
      middleware: [auth],
      resource: RESOURCE_CONFIGURATION,
      headerVisible: false,
    },
    name: 'salesOrdersReports',
    component: () => import('@/views/sales-orders-reports/List.vue'),
  },
  {
    path: '/orders-products',
    meta: {
      middleware: [auth],
      resource: RESOURCE_CONFIGURATION,
      headerVisible: false,
    },
    name: 'ordersProducts',
    component: () => import('@/views/orders-products/List.vue'),
  },
  {
    path: '/orders-products-categories',
    meta: {
      middleware: [auth],
      resource: RESOURCE_CONFIGURATION,
      headerVisible: false,
    },
    name: 'ordersProductsCategories',
    component: () => import('@/views/orders-products-categories/List.vue'),
  },
]
