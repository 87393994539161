import Vue from 'vue'
import Vuex from 'vuex'

import app from '@/store/app'
import appConfig from '@/store/app-config'
import verticalMenu from '@/store/vertical-menu'
import notifications from '@/store/notifications'
import clients from '@/store/clients'
import offer from '@/store/offer'
import category from '@/store/category'
import auth from '@/store/auth'
import saleInvoice from '@/store/sale-invoice'
import purchaseInvoice from '@/store/purchase-invoice'
import orders from '@/store/orders'
import deliveryNotes from '@/store/delivery-notes'
import job from '@/store/job'
import maintenanceCertifications from '@/store/maintenance-certifications'
import certifications from '@/store/certifications'
import projects from '@/store/projects'
import providers from '@/store/providers'
import products from '@/store/products'
import nonConformities from '@/store/non-conformities'
import users from '@/store/users'
import jobReports from '@/store/job-reports'
import jobReportsServices from '@/store/job-reports-services'
import purchaseInvoiceReports from '@/store/purchase-invoice-reports'
import saleInvoicesReports from '@/store/sale-invoices-reports'
import financialLimitReports from '@/store/financial-limit-reports'
import orderProducts from '@/store/order-products'
import ordersProductsCategories from '@/store/orders-products-categories'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    notifications,
    clients,
    offer,
    category,
    auth,
    orders,
    saleInvoice,
    purchaseInvoice,
    deliveryNotes,
    job,
    certifications,
    projects,
    providers,
    products,
    nonConformities,
    users,
    maintenanceCertifications,
    jobReports,
    purchaseInvoiceReports,
    saleInvoicesReports,
    jobReportsServices,
    financialLimitReports,
    orderProducts,
    ordersProductsCategories
  },
  strict: process.env.DEV,
})
